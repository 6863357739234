<template>
  <div>
    <ContainerBase>
      <AppHeaderLogo />

      <MessageFullScreen>
        <template #illustration>
          <IllustrationForbidden />
        </template>

        <template #title>Access denied</template>
        <template #subtitle>You have no access to the Performetry </template>
        <template #footer>
          <NuxtLink to="/logout" class="inline-flex">
            <BtnBase color="blue" btn-style="flat" width="240px"> Logout </BtnBase>
          </NuxtLink>
        </template>
      </MessageFullScreen>
    </ContainerBase>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ContainerBase from '~/shared/ui/container/container-base.vue';
import AppHeaderLogo from '~/shared/ui/app/app-header-logo.vue';
import MessageFullScreen from '~/shared/ui/message/message-full-screen.vue';
import IllustrationForbidden from '~/shared/ui/illustration/illustration-forbidden.vue';
import BtnBase from '~/shared/ui/btn/btn-base.vue';

export default defineComponent({
  name: 'AppStateForbidden',

  components: {
    BtnBase,
    IllustrationForbidden,
    MessageFullScreen,
    AppHeaderLogo,
    ContainerBase,
  },
});
</script>

<style lang="scss"></style>
